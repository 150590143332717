import * as LabelPrimitive from "@radix-ui/react-label";
import { Slot } from "@radix-ui/react-slot";
import { CalendarIcon } from "lucide-react";
import * as React from "react";
import {
  Control,
  Controller,
  ControllerProps,
  FieldPath,
  FieldValues,
  FormProvider,
  useFormContext,
} from "react-hook-form";
import ReactSelect from "react-select";
import AsyncSelect from "react-select/async";

import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { Checkbox } from "@/components/ui/checkbox";
import { Label } from "@/components/ui/label";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Switch } from "@/components/ui/switch";
import { Textarea } from "@/components/ui/textarea";
import { cn } from "@/lib/utils";
import dayjs from "@/packages/dayjs";

import { TiptapEditor } from "./form/editor";
import {
  AttatchmentDropZone,
  AttatchmentUploadDropZone,
  FileUploadDropZone,
} from "./form/upload";
import { FormInput } from "./input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./select";

const Form = FormProvider;

type FormFieldContextValue<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  name: TName;
};

const FormFieldContext = React.createContext<FormFieldContextValue>(
  {} as FormFieldContextValue,
);

const FormField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  ...props
}: ControllerProps<TFieldValues, TName>) => {
  return (
    <FormFieldContext.Provider value={{ name: props.name }}>
      <Controller {...props} />
    </FormFieldContext.Provider>
  );
};

const useFormField = () => {
  const fieldContext = React.useContext(FormFieldContext);
  const itemContext = React.useContext(FormItemContext);
  const { getFieldState, formState } = useFormContext();

  const fieldState = getFieldState(fieldContext.name, formState);

  if (!fieldContext) {
    throw new Error("useFormField should be used within <FormField>");
  }

  const { id } = itemContext;

  return {
    id,
    name: fieldContext.name,
    formItemId: `${id}-form-item`,
    formDescriptionId: `${id}-form-item-description`,
    formMessageId: `${id}-form-item-message`,
    ...fieldState,
  };
};

type FormItemContextValue = {
  id: string;
};

const FormItemContext = React.createContext<FormItemContextValue>(
  {} as FormItemContextValue,
);

const FormItem = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  const id = React.useId();

  return (
    <FormItemContext.Provider value={{ id }}>
      <div ref={ref} className={cn("space-y-2", className)} {...props} />
    </FormItemContext.Provider>
  );
});
FormItem.displayName = "FormItem";

const FormLabel = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root>
>(({ className, ...props }, ref) => {
  const { error, formItemId } = useFormField();

  return (
    <Label
      ref={ref}
      className={cn(error, className)}
      htmlFor={formItemId}
      {...props}
    />
  );
});
FormLabel.displayName = "FormLabel";

const FormControl = React.forwardRef<
  React.ElementRef<typeof Slot>,
  React.ComponentPropsWithoutRef<typeof Slot>
>(({ ...props }, ref) => {
  const { error, formItemId, formDescriptionId, formMessageId } =
    useFormField();

  return (
    <Slot
      ref={ref}
      id={formItemId}
      aria-describedby={
        !error
          ? `${formDescriptionId}`
          : `${formDescriptionId} ${formMessageId}`
      }
      aria-invalid={!!error}
      {...props}
    />
  );
});
FormControl.displayName = "FormControl";

const FormDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => {
  const { formDescriptionId } = useFormField();

  return (
    <p
      ref={ref}
      id={formDescriptionId}
      className={cn("text-sm text-muted-foreground", className)}
      {...props}
    />
  );
});
FormDescription.displayName = "FormDescription";

const FormMessage = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, children, ...props }, ref) => {
  const { error, formMessageId } = useFormField();
  const body = error ? String(error?.message) : children;

  if (!body) {
    return null;
  }

  return (
    <p
      ref={ref}
      id={formMessageId}
      className={cn("text-sm text-error", className)}
      {...props}
    >
      {body}
    </p>
  );
});
FormMessage.displayName = "FormMessage";

export interface FormItemFieldProps {
  control: Control<any>;
  name: string;
  placeholder?: string;
  type?: string;
  label?: string;
  desc?: string;
  topDesc?: string;
  className?: string;
  inputProps?: any;
  postfix?: React.ReactNode;
  prefix?: string;
  input?: (value: string) => string | number;
  output?: (value: string) => string | number | undefined;
  labelClass?: string;
  descClass?: string;
  rules?: any;
  required?: boolean;
  customLabelClass?: string;
  customLabel?: React.ReactElement;
  customOnChange?: (e: any) => void;
  disableFormMessage?: boolean;
}

const FormItemField = (props: FormItemFieldProps) => (
  <FormField
    control={props.control}
    name={props.name}
    rules={props.rules}
    render={({ field }) => (
      <FormItem className={props.className}>
        <FormLabel
          className={
            props?.customLabelClass ||
            cn(
              "p2-medium-mobile mt-2 font-medium text-coffee-500",
              props.labelClass,
            )
          }
        >
          {props.label}{" "}
          {(props.required || props.rules?.required) && (
            <span className="text-interface-error">*</span>
          )}
          <FormDescription className="p4 mt-0 text-coffee-500">
            {props.topDesc}
          </FormDescription>
        </FormLabel>
        <FormControl>
          <div className="relative">
            {props.prefix && (
              <span className="absolute left-2 top-[0.6rem] text-lg text-coffee-900">
                {props.prefix}
              </span>
            )}
            <FormInput
              placeholder={props.placeholder}
              {...field}
              type={props.type || "text"}
              {...props.inputProps}
              required={props.required}
              className={cn(
                "p2 placeholder:p2 mt-2 h-12 bg-white placeholder:text-lg",
                props.prefix && "pl-6",
                props.inputProps?.className,
              )}
              onChange={(e) => {
                field.onChange(props.output ? props.output(e.target.value) : e);
                props?.customOnChange && props.customOnChange(e.target.value);
              }}
            />
            {props.postfix && props.postfix}
          </div>
        </FormControl>
        <FormMessage />
        <FormDescription>{props.desc}</FormDescription>
      </FormItem>
    )}
  />
);

const FormItemNumberField = (props: FormItemFieldProps) => (
  <FormField
    control={props.control}
    name={props.name}
    rules={props.rules}
    render={({ field }) => (
      <FormItem className={props.className}>
        <FormLabel
          className={cn(
            "p2-medium-mobile mt-2 text-coffee-500",
            props.labelClass,
          )}
        >
          {props.label}{" "}
          {(props.required || props.rules?.required) && (
            <span className="text-interface-error">*</span>
          )}
          <FormDescription className="p4 mt-0 text-coffee-500">
            {props.topDesc}
          </FormDescription>
        </FormLabel>
        <FormControl>
          <div className="relative">
            {props.prefix && (
              <span className="absolute left-2 top-2 text-slate-400">
                {props.prefix}
              </span>
            )}
            <FormInput
              className={cn("h-12 text-base", props.prefix && "pl-6")}
              placeholder={props.placeholder}
              {...field}
              onChange={(e) => {
                const floatRegex = /^[+-]?([0-9]*[.])?[0-9]+$/;
                const inputValue = e.target.value;
                if (floatRegex.test(inputValue)) {
                  return field.onChange(parseFloat(e.target.value));
                } else {
                  return field.onChange(e.target.value);
                }
              }}
              onKeyDown={(e) => {
                const key = e.key;
                // Allow numbers, dot, tab, backspace, enter, and arrow keys
                if (
                  ![
                    "Backspace",
                    "Tab",
                    "Enter",
                    "ArrowLeft",
                    "ArrowRight",
                    "Escape",
                  ].includes(key) &&
                  !/\d|\./.test(key)
                ) {
                  e.preventDefault();
                }
              }}
              value={field.value}
              type={props.type || "text"}
              {...props.inputProps}
            />
            {props.postfix && props.postfix}
          </div>
        </FormControl>
        <FormMessage />
        <FormDescription>{props.desc}</FormDescription>
      </FormItem>
    )}
  />
);

const FormItemTextEditor = (props: FormItemFieldProps) => {
  return (
    <FormField
      control={props.control}
      name={props.name}
      rules={props.rules}
      render={({ field }) => (
        <FormItem className={props.className}>
          <FormLabel
            className={cn("p2-medium-mobile text-coffee-500", props.labelClass)}
          >
            {props.label}{" "}
            {(props.required || props.rules?.required) && (
              <span className="text-interface-error">*</span>
            )}
            <FormDescription className="p4 mt-0 text-coffee-500">
              {props.topDesc}
            </FormDescription>
          </FormLabel>
          <FormControl>
            <TiptapEditor
              initialValue={field.value}
              onChange={field.onChange}
              placeholder={props.placeholder}
              {...props.inputProps}
            />
          </FormControl>
          <FormDescription>{props.desc}</FormDescription>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

const FormItemTextArea = (props: FormItemFieldProps) => {
  return (
    <FormField
      control={props.control}
      name={props.name}
      rules={props.rules}
      render={({ field }) => (
        <FormItem className={props.className}>
          {props?.customLabel ? (
            props.customLabel
          ) : (
            <FormLabel
              className={cn(
                "p2-medium-mobile text-coffee-500",
                props.labelClass,
              )}
            >
              {props.label}{" "}
              {(props.required || props.rules?.required) && (
                <span className="text-interface-error">*</span>
              )}
              <FormDescription className="p4 mt-0 text-coffee-500">
                {props.topDesc}
              </FormDescription>
            </FormLabel>
          )}
          <FormControl>
            <Textarea
              placeholder={props.placeholder}
              {...field}
              type={props.type || "text"}
              {...props.inputProps}
              className={cn(
                "p2 placeholder:p2 mt-3 h-[51px] bg-white placeholder:text-lg",
                props.inputProps?.className,
              )}
              onChange={(e) => {
                field.onChange(e);
                if (props.customOnChange) {
                  props.customOnChange(e);
                }
              }}
            />
          </FormControl>
          <FormDescription>{props.desc}</FormDescription>
          {!props.disableFormMessage && <FormMessage />}
        </FormItem>
      )}
    />
  );
};

interface FormItemSelectProps extends FormItemFieldProps {
  options: { value: any; label: any }[];
  scrollClass?: string;
  defaultValue?: string;
  required?: boolean;
}

const FormItemSelect = (props: FormItemSelectProps) => {
  return (
    <FormField
      control={props.control}
      name={props.name}
      rules={props.rules}
      render={({ field }) => (
        <FormItem className={props.className}>
          <FormLabel
            className={cn("p2-medium-mobile text-coffee-500", props.labelClass)}
          >
            {props.label}{" "}
            {(props.required || props.rules?.required) && (
              <span className="text-interface-error">*</span>
            )}
            <FormDescription className="p4 mt-0 text-coffee-500">
              {props.topDesc}
            </FormDescription>
          </FormLabel>
          <Select
            onValueChange={field.onChange}
            value={field.value}
            defaultValue={props.defaultValue}
            required={props.required}
          >
            <FormControl>
              <SelectTrigger aria-label={props.label} {...props.inputProps}>
                <SelectValue
                  placeholder={props.placeholder}
                  className="text-primary"
                />
              </SelectTrigger>
            </FormControl>
            <SelectContent>
              <div
                className={cn(
                  "overflow-y-auto",
                  props.scrollClass || "max-h-96",
                )}
              >
                {props.options.map((option: any) => (
                  <>
                    <SelectItem key={option.value} value={option.value}>
                      {option.label}
                    </SelectItem>
                  </>
                ))}
              </div>
            </SelectContent>
          </Select>
          <FormDescription>{props.desc}</FormDescription>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

interface FormItemFileUploadProps extends FormItemFieldProps {
  children?: React.ReactNode;
}

const FormItemFileUpload = (props: FormItemFileUploadProps) => {
  return (
    <FormField
      control={props.control}
      name={props.name}
      render={({ field }) => (
        <FormItem className={props.className}>
          <FormLabel>{props.label}</FormLabel>
          <FormControl>
            <FileUploadDropZone
              onChange={(files) => {
                field.onChange([...field.value, ...files]);
              }}
              {...props.inputProps}
            >
              {props.children}
            </FileUploadDropZone>
          </FormControl>
          <FormDescription>{props.desc}</FormDescription>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

const FormItemAttachmentUpload = (props: FormItemFileUploadProps) => {
  return (
    <FormField
      control={props.control}
      name={props.name}
      render={({ field }) => (
        <FormItem className={props.className}>
          <FormLabel>{props.label}</FormLabel>
          <FormControl>
            <AttatchmentUploadDropZone
              onChange={(data) => {
                field.onChange(data);
              }}
              {...props.inputProps}
            >
              {props.children}
            </AttatchmentUploadDropZone>
          </FormControl>
          <FormDescription>{props.desc}</FormDescription>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

const FormItemAttachment = (props: FormItemFileUploadProps) => {
  return (
    <FormField
      control={props.control}
      name={props.name}
      render={({ field }) => (
        <FormItem className={props.className}>
          <FormLabel>{props.label}</FormLabel>
          <FormControl>
            <AttatchmentDropZone
              onChange={(data) => {
                field.onChange(data);
              }}
              {...props.inputProps}
            >
              {props.children}
            </AttatchmentDropZone>
          </FormControl>
          <FormDescription>{props.desc}</FormDescription>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

const FormItemCheckbox = (props: FormItemFieldProps) => (
  <FormField
    control={props.control}
    name={props.name}
    render={({ field }) => (
      <FormItem className={cn("", props.className)}>
        <div className="flex space-x-2">
          <FormControl>
            <Checkbox
              checked={field.value}
              onCheckedChange={field.onChange}
              {...props.inputProps}
            />
          </FormControl>
          <div className="space-y-1 leading-none">
            {props?.customLabel ? (
              props.customLabel
            ) : (
              <FormLabel className={props.labelClass}>{props.label}</FormLabel>
            )}
            <FormDescription className={props.descClass}>
              {props.desc}
            </FormDescription>
          </div>
        </div>
        <FormMessage />
      </FormItem>
    )}
  />
);

const FormItemRadioGroup = (props: FormItemSelectProps) => (
  <FormField
    control={props.control}
    name={props.name}
    render={({ field }) => (
      <FormItem className={cn("flex space-x-3", props.className)}>
        <FormControl>
          <RadioGroup value={field.value} onValueChange={field.onChange}>
            {props.options.map((option: any) => (
              <div className="flex space-x-3" key={option.value}>
                <RadioGroupItem value={option.value} id={option.value} />
                <Label htmlFor={option.value}>{option.label}</Label>
              </div>
            ))}
          </RadioGroup>
        </FormControl>
        <div className="space-y-1 leading-none">
          <FormDescription className={props.descClass}>
            {props.desc}
          </FormDescription>
        </div>
        <FormMessage />
      </FormItem>
    )}
  />
);

const FormItemSwitch = (props: FormItemFieldProps) => (
  <FormField
    control={props.control}
    name={props.name}
    render={({ field }) => (
      <FormItem
        className={cn(
          "items-base-line flex gap-3 space-x-0 space-y-0 py-3",
          props.className,
        )}
      >
        <FormControl>
          <Switch
            checked={field.value}
            onCheckedChange={field.onChange}
            {...props.inputProps}
          />
        </FormControl>
        <div className="">
          <FormLabel
            className={cn("p2-medium text-emerald-black", props.labelClass)}
          >
            {props.label}
          </FormLabel>
          <FormDescription
            className={cn("p4 text-coffee-500", props.descClass)}
          >
            {props.desc}
          </FormDescription>
        </div>
        <FormMessage />
      </FormItem>
    )}
  />
);

const FormItemDatePicker = (props: FormItemFieldProps) => (
  <FormField
    control={props.control}
    name={props.name}
    render={({ field }) => (
      <FormItem className="flex flex-col">
        <FormLabel className={props.labelClass}>
          {props.label}{" "}
          {(props.required || props.rules?.required) && (
            <span className="text-interface-error">*</span>
          )}
        </FormLabel>
        <Popover>
          <PopoverTrigger asChild>
            <FormControl>
              <Button
                variant={"outline"}
                className={cn(
                  "pl-3 text-left font-normal",
                  props.className,
                  !field.value && "text-muted-foreground",
                )}
                {...props.inputProps}
              >
                {Boolean(field.value)
                  ? dayjs(field.value).format("LL")
                  : props.placeholder}
                <CalendarIcon className="ml-auto h-5 w-5" />
              </Button>
            </FormControl>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0" align="end">
            <Calendar
              mode="single"
              selected={field.value}
              onSelect={field.onChange}
              className="mb-2 p-8 shadow-calendar"
              // disabled={(date) =>
              //   date > new Date() || date < new Date("1900-01-01")
              // }
            />
          </PopoverContent>
        </Popover>
        <FormDescription>{props.desc}</FormDescription>
        <FormMessage />
      </FormItem>
    )}
  />
);

const FormItemMultiSelect = (props: FormItemSelectProps) => (
  <FormField
    control={props.control}
    name={props.name}
    render={({ field }) => (
      <FormItem className={props.className}>
        <FormLabel
          className={cn(
            "p2-medium mt-2 text-lg font-medium text-coffee-500",
            props.labelClass,
          )}
        >
          {props.label}{" "}
          {(props.required || props.rules?.required) && (
            <span className="text-interface-error">*</span>
          )}
          <FormDescription className="p4 mt-0 text-coffee-500">
            {props.topDesc}
          </FormDescription>
        </FormLabel>
        <ReactSelect
          onChange={(val: any) => field.onChange(val.map((c: any) => c.value))}
          value={props.options.filter((c) =>
            (field.value || []).includes(c.value),
          )}
          placeholder={props.placeholder}
          options={props.options}
          isMulti
          {...props.inputProps}
        />
        <FormDescription>{props.desc}</FormDescription>
        <FormMessage />
      </FormItem>
    )}
  />
);

interface FormItemAsyncSelectProps extends FormItemFieldProps {
  defaultOptions?: any[];
  loadOptions: (input: string) => Promise<any>;
}

const FormItemAsyncSelect = (props: FormItemAsyncSelectProps) => (
  <FormField
    control={props.control}
    name={props.name}
    render={({ field }) => (
      <FormItem className={props.className}>
        <FormLabel className={props.labelClass}>{props.label}</FormLabel>
        <AsyncSelect
          onChange={field.onChange}
          value={field.value}
          placeholder={props.placeholder}
          isClearable
          isSearchable
          defaultOptions={props.defaultOptions}
          loadOptions={props.loadOptions}
          {...props.inputProps}
        />
        <FormDescription>{props.desc}</FormDescription>
        <FormMessage />
      </FormItem>
    )}
  />
);

export {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormItemAsyncSelect,
  FormItemAttachment,
  FormItemAttachmentUpload,
  FormItemCheckbox,
  FormItemDatePicker,
  FormItemField,
  FormItemFileUpload,
  FormItemMultiSelect,
  FormItemNumberField,
  FormItemRadioGroup,
  FormItemSelect,
  FormItemSwitch,
  FormItemTextArea,
  FormItemTextEditor,
  FormLabel,
  FormMessage,
  useFormField,
};
